@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

.back {
  z-index: -1;
}
